<template>
  <div>
    <div v-if="options">
      <b-form-group>
        <div class="mb-2">
          {{ label }} <span class="text-danger" v-if="required">*</span>
          <slot name="icon"></slot>
        </div>
        <b-form-checkbox-group
          v-model="content"
          :options="options"
          :value-field="valueField"
          :text-field="textField"
          :state="state"
          :stacked="stacked"
          @change="handleClick"
          :disabled="disabled"
        >
        </b-form-checkbox-group>
        <b-form-invalid-feedback :state="state">
          {{
            $t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
              name: this.label,
            })
          }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div v-else>
      <b-form-checkbox
        v-model="content"
        :required="required"
        :checked="checked"
        :unchecked-value="unchecked"
        :state="state"
        @change="handleClick"
        :disabled="disabled"
      >
        {{ label }} <span class="text-danger" v-if="required">*</span>
        <slot name="icon"></slot>
      </b-form-checkbox>
      <b-form-invalid-feedback :state="state">
        {{
          $t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
            name: this.label ? this.label : '',
          })
        }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputCheckbox',
  props: {
    value: { type: [String, Number, Object, Array, Boolean] },
    label: { type: String },
    required: { type: Boolean },
    options: { type: Array },
    valueField: { type: String, default: 'value' },
    textField: { type: String, default: 'text' },
    stacked: { type: Boolean, default: false },
    checked: { type: [Boolean, String, Number], default: true },
    unchecked: { type: [Boolean, String, Number], default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
      feedback: '',
      validForm: false,
      changed: false,
      isField: true,
    };
  },
  computed: {
    wasChanged() {
      return this.changed;
    },
    state() {
      if (!this.validForm) return null;

      return !(
        this.required &&
        (this.content == undefined || this.content == false)
      );
    },
  },
  methods: {
    handleClick(value) {
      this.content = value;
      this.$emit('input', this.content);
      this.$emit('change');
      this.changed = true;
    },
    handleInput() {
      this.$emit('input', this.content);
    },
    valid() {
      this.validForm = true;
      return this.state;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
