<template>
  <b-form-group :label-sr-only="noLabel">
    <template #label>
      <span>{{ $t(label) }}</span>
      <span v-if="required && label" class="text-danger">*</span>
    </template>
    <b-input-group>
      <b-form-input
        v-model="content"
        :state="state"
        @input="handleInput"
        type="number"
        :readonly="readonly"
        :formatter="formatter"
        :placeholder="this.$t(placeholder)"
        :lazy="lazy"
        @wheel="$event.target.blur()"
        :min="min"
        :max="max"
        :size="size"
        @change="setChanged"
        :disabled="disabled"
        :decimal="decimal"
        :step="step"
      />
      <slot name="append"></slot>
      <b-form-invalid-feedback v-if="!hideFeedback">{{
        feedback
      }}</b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'InputNumber',
  props: {
    stateCustom: { type: Boolean, default: true },
    value: { type: [String, Number], default: '' },
    contactType: { type: String, default: '' },
    label: { type: String },
    size: { type: String },
    upper: { type: Boolean },
    noLabel: { type: Boolean, default: false },
    removeSpecialChars: { type: Boolean },
    removeWhiteSpaces: { type: Boolean },
    required: { type: Boolean },
    requiredMessage: { type: String },
    readonly: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    lengthMessage: { type: String },
    minLength: { type: Number, default: null },
    minLengthMessage: { type: String },
    maxLength: { type: Number, default: null },
    maxLengthMessage: { type: String },
    placeholder: { type: String },
    validate: { type: Boolean, default: null },
    lazy: { type: Boolean, default: false },
    min: { type: [Number, String], required: false },
    max: { type: [Number, String], required: false },
    hideFeedback: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    decimal: { type: Boolean, default: false },
    step: { type: String, default: String },
    number: { type: Boolean, default: true },
    limparValidacao: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
      validForm: false,
      hasError: false,
      errorMessage: '',
      changed: false,
      isField: true,
    };
  },
  computed: {
    wasChanged() {
      return this.changed;
    },
    requiredValid() {
      return !(this.required && (this.content == '' || this.content == null));
    },
    minValid() {
      return !(this.min && parseInt(this.content) < parseInt(this.min));
    },
    maxValid() {
      return !(this.max && parseInt(this.content) > parseInt(this.max));
    },
    lengthValid() {
      return !(this.length > 0 && String(this.content)?.length != this.length);
    },
    minLengthValid() {
      return !(this.minLength > 0 && this.content?.length < this.minLength);
    },
    maxLengthValid() {
      return !(this.maxLength > 0 && this.content?.length > this.maxLength);
    },
    state() {
      if (!this.stateCustom) return false;
      if (!this.validForm) return null;
      if (!this.requiredValid) return false;
      if (!this.minValid) return false;
      if (!this.maxValid) return false;
      if (!this.lengthValid) return false;
      if (!this.minLengthValid) return false;
      if (!this.maxLengthValid) return false;
      if (this.hasError) return false;
      if (this.validate != null) return this.validate;

      return true;
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
            name: this.label,
          })
        );
      if (!this.minValid)
        return (
          this.requiredMessage ||
          this.$t('CAMPOS_VALIDACAO.VALOR_MINIMO').formatUnicorn({
            min: this.min,
          })
        );
      if (!this.maxValid)
        return (
          this.requiredMessage ||
          this.$t('CAMPOS_VALIDACAO.VALOR_MAXIMO').formatUnicorn({
            max: this.max,
          })
        );
      if (!this.lengthValid)
        return (
          this.lengthMessage ||
          this.$t('CAMPOS_VALIDACAO.TAMANHO').formatUnicorn({
            name: this.label,
            length: this.length,
          })
        );
      if (!this.minLengthValid)
        return (
          this.minLengthMessage ||
          this.$t('CAMPOS_VALIDACAO.COMPRIMENTO_MINIMO').formatUnicorn({
            name: this.label,
            length: this.minLength,
          })
        );
      if (!this.maxLengthValid)
        return (
          this.maxLengthMessage ||
          this.$t('CAMPOS_VALIDACAO.COMPRIMENTO_MAXIMO').formatUnicorn({
            name: this.label,
            length: this.maxLength,
          })
        );
      if (this.hasError) return this.errorMessage;
      return '';
    },
  },
  methods: {
    showError(message) {
      this.validForm = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    formatter(e) {
      if (e) {
        if (this.upper) e = e.toUpperCase();

        if (this.decimal) {
          //let numero = e.replace(/\D/g, "");
          let numero = e;
          if (this.maxLength == 10) {
            numero = numero.replace(/^(\d)(\d{2}).*/, '$1.$2');
          }
          if (this.maxLength == 100) {
            numero = numero.replace(/^(\d\d)(\d{2}).*/, '$1.$2');
          }
          return numero;
        }

        if (this.max > 0 && e.length > this.max) {
          e = e.substring(0, this.max);
        }
        if (this.min > 0 && e.length > this.min) {
          e = e.substring(0, this.min);
        }

        if (this.maxLength > 0 && e > this.maxLength) {
          e = e.substring(this.maxLength);
        }

        if (this.removeSpecialChars)
          e = e.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');

        if (this.removeWhiteSpaces) e = e.replace(/\s/g, '');
      }

      return e;
    },
    handleInput() {
      this.$emit('input', this.content);
      this.hasError = false;
    },
    valid() {
      this.validForm = true;
      return this.state;
    },
    clear() {
      this.content = '';
      this.validForm = false;
      this.$emit('input', this.content);
    },
    sendEnterEvent() {
      this.$emit('enter');
    },
    setChanged() {
      this.changed = true;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = Number(value);
      },
      deep: true,
      immediate: true,
    },
    content(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
    limparValidacao: {
      handler(value) {
        if (value) {
          this.clear();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  min-width: 100px !important;
}
input[readonly] {
  background-color: #f2f3f8 !important;
}
</style>
