<template>
  <b-form-group :label-sr-only="noLabel">
    <template #label>
      <span>{{ $t(label) }}</span>
      <span v-if="required && label" class="text-danger">*</span>
    </template>
    <b-input-group>
      <div class="text-info loader" v-if="loading">
        <b-spinner class="align-middle spinner"></b-spinner>
      </div>
      <b-form-select
        v-model="content"
        :state="state"
        trim
        @input="handleInput"
        :options="options"
        :value-field="valueField"
        :text-field="textField"
        :size="size"
        @change="setChanged"
        :disabled="disabled"
      >
        <template #first v-if="!hideFirstOption">
          <b-form-select-option :value="null" :disabled="required">{{
            mostraTodos ? 'Todos' : ''
          }}</b-form-select-option>
        </template>
      </b-form-select>
      <slot name="append"></slot>
      <b-form-invalid-feedback :state="state">{{
        feedback
      }}</b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'InputSelect',

  props: {
    value: { type: [String, Number, Object, Array] },
    label: { type: String },
    required: { type: Boolean },
    loading: { type: Boolean },
    requiredMessage: { type: String },
    options: { type: Array, required: true },
    valueField: { type: String, default: 'value' },
    textField: { type: String, default: 'text' },
    size: { type: String, default: '' },
    noLabel: { type: Boolean, default: false },
    hideFirstOption: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    limparValidacao: { type: Boolean, default: false },
    mostraTodos: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
      validarFormulario: false,
      validState: false,
      hasError: false,
      errorMessage: '',
      changed: false,
      isField: true,
    };
  },
  computed: {
    wasChanged() {
      return this.changed;
    },
    requiredValid() {
      return !(this.required && !this.value);
    },
    state() {
      if (!this.validState) return null;
      if (!this.validarFormulario && !this.value) return null;

      if (!this.requiredValid) return false;
      if (this.hasError) return false;

      return true;
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
            name: this.label,
          })
        );

      if (this.hasError) return this.errorMessage;
      return '';
    },
  },

  methods: {
    showError(message) {
      this.validarFormulario = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    handleInput() {
      this.$emit('input', this.content);
      this.hasError = false;
    },
    valid() {
      this.validarFormulario = true;
      this.validState = this.state === null || this.state === false;
      return this.state;
    },
    clear() {
      this.content = '';
      this.validarFormulario = false;
      this.$emit('input', this.content);
    },
    setChanged() {
      this.changed = true;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.loader {
  position: absolute;
  padding: 9px;
  z-index: 1;
}

.spinner {
  width: 18px;
  height: 18px;
}
.input-group:not(.has-validation) > .custom-select:not(:last-child) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
