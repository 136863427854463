<template>
  <div :class="[isDisabled, classError]" class="input-select-multiple mb-0">
    <label v-if="!hideLabel" class="typo__label"
      >{{ this.$t(label) }}
      <span
        v-if="required && label"
        class="text-danger"
        style="margin-left: -4px"
        >*</span
      >
    </label>
    <multiselect
      v-model="content"
      :options="options"
      :multiple="false"
      :value="value"
      :close-on-select="true"
      :clear-on-select="clearOnSelect"
      :preserve-search="true"
      :placeholder="$t('GERAL.SELECIONE')"
      :show-labels="true"
      :label="textField"
      :track-by="valueField"
      @input="handleInput"
      :searchable="true"
      :internal-search="true"
      :show-no-results="false"
      :hide-selected="computedHideSelected"
      @search-change="asyncFind"
      @select="addOption"
      @tag="addOption"
      :disabled="disabled"
      :selectedLabel="$t('GERAL.SELECIONADO')"
      selectLabel=""
      deselectLabel=""
      :limit="limitComputed"
      :limitText="(count) => `+ ${count}`"
    >
      <template slot="noOptions">{{
        noOptions || $t('GERAL.LISTA_VAZIA')
      }}</template>
    </multiselect>
    <label
      class="typo__label form__label feedback__text"
      v-show="state === false"
      >{{ feedback }}</label
    >
    <div
      class="spinner-border text-info spinner-grow-sm loading-select"
      role="status"
      v-if="loading"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'InputSelectMultiple',

  props: {
    value: { type: [String, Number, Object, Array] },
    hideLabel: { type: Boolean, default: false },
    label: { type: String, required: true },
    required: { type: Boolean },
    disabled: { type: Boolean, default: false },
    requiredMessage: { type: String },
    options: { type: Array, required: true },
    valueField: { type: String, default: 'value' },
    textField: { type: String, default: 'text' },
    loading: { type: Boolean, default: false },
    noOptions: { type: String },
    clearOnSelect: { type: Boolean, default: false },
    limit: { type: [Number, String], default: 'default' },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      content: this.value,
      validarFormulario: false,
      hasError: false,
      errorMessage: '',
      changed: false,
      isField: true,
    };
  },
  computed: {
    requiredValid() {
      return !(this.required && !this.checkArray);
    },
    checkArray() {
      if (!this.value) return false;
      if (Array.isArray(this.value)) if (this.value.length == 0) return false;
      return true;
    },
    limitComputed() {
      if (this.limit === 'default') return 3;
      else return this.limit;
    },
    computedHideSelected() {
      return this.limit === 'default';
    },
    state() {
      if (!this.validarFormulario) return null;

      if (!this.requiredValid) return false;
      if (this.hasError) return false;

      return true;
    },
    feedback() {
      if (!this.requiredValid) {
        if (this.requiredMessage) {
          return this.requiredMessage;
        } else {
          return this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
            name: this.label,
          });
        }
      }

      if (this.hasError) return this.errorMessage;
      return '';
    },
    classError() {
      if (this.state == false) return 'invalid';
      else if (this.state == true) return 'valid';
      return '';
    },
    isDisabled() {
      return this.disabled ? 'disabled' : '';
    },
    wasChanged() {
      return this.changed;
    },
  },
  methods: {
    asyncFind(query) {
      this.$emit('search', query);
    },
    showError(message) {
      this.validarFormulario = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    addOption() {
      this.changed = true;
      setTimeout(() => {
        this.$emit('add');
      }, 200);
    },
    handleInput() {
      this.$emit('input', this.content);
      this.hasError = false;
    },
    valid() {
      this.validarFormulario = true;
      return this.state;
    },
    clear() {
      this.content = '';
      this.validarFormulario = false;
      this.$emit('input', this.content);
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.custom-select:disabled {
  background-color: #e9ecef !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.multiselect {
  min-height: 35px;
}
.invalid {
  .multiselect__tags {
    border: 1px red solid;
  }
}
.valid {
  .multiselect__tags {
    border-color: #0bb7af;
  }
}
.multiselect__tags {
  min-height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1px 26px 1px 10px !important;
}
.multiselect__input,
.multiselect__single {
  /* margin: 0; */
  padding: 0;
  margin-top: 10px;
}
.multiselect__tag {
  margin-bottom: 1px;
  background: #2962ff;
  margin-top: 2px;
}
.typo__label {
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
}

.multiselect.multiselect--disabled > .multiselect__tags {
  background: #e9ecef !important;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background-color: transparent !important;
}

.multiselect--disabled {
  background-color: transparent;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px;
}
.feedback__text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.multiselect__strong {
  font-size: 13px;
  padding-top: 6px;
}
.multiselect__placeholder {
  padding-top: 8.5px;
  margin-bottom: 5px;
}
.multiselect__option--highlight {
  background: #2962ff;
}
.multiselect__select {
  height: 40px;
}

.input-select-multiple {
  width: 100%;
  margin: 0 !important;
}
.loading-select {
  position: absolute;
  top: 43px;
  right: 45px;
}
</style>
