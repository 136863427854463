<template>
  <b-form-group :label-sr-only="noLabel">
    <template #label>
      <span>{{ $t(label) }}</span>
      <span v-if="required && label" class="text-danger">*</span>
    </template>
    <b-input-group>
      <b-form-input
        v-model="content"
        type="text"
        :state="state"
        :readonly="readonly"
        :formatter="formatter"
        :placeholder="this.$t(placeholder)"
        :lazy="lazy"
        :disabled="disabled"
        @input="handleInput"
        @wheel="$event.target.blur()"
        @keyup="sendKeydownEvent"
      />
      <slot name="append"></slot>
      <b-form-invalid-feedback>
        {{ feedback }}
      </b-form-invalid-feedback>
    </b-input-group>

    <!-- EXIBIÇÃO DO LIMITE DE CARACTERES PERMITIDOS -->
    <small class="lengthLimit__text" v-if="maxLength">
      {{ lengthLimit }}
    </small>
  </b-form-group>
</template>

<script>
// Utils & Aux:
import formatador from '@/common/utils/formatador';

export default {
  name: 'InputCnpj',
  props: {
    value: { type: [String, Number], default: '' },
    label: { type: String, default: 'FINANCEIRO.CNPJ' },
    placeholder: { type: String, default: 'FINANCEIRO.CNPJ' },
    noLabel: { type: Boolean, default: false },
    required: { type: Boolean },
    requiredMessage: { type: String },
    readonly: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    lengthMessage: { type: String },
    minLength: { type: Number, default: 18 },
    minLengthMessage: { type: String },
    maxLength: { type: Number, default: 18 },
    maxLengthMessage: { type: String },
    lazy: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value,
      validForm: false,
      hasError: false,
      errorMessage: '',
      lengthLimit: `0/${this.maxLength}`,
    };
  },
  mounted() {
    this.content = this.regexCNPJ(this.value);
  },
  watch: {
    value: {
      handler(value) {
        this.content = value;
      },
      deep: true,
      immediate: true,
    },
    content(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
  },
  computed: {
    requiredValid() {
      return !(this.required && (this.content == '' || this.content == null));
    },
    lengthValid() {
      return !(this.length > 0 && String(this.content)?.length != this.length);
    },
    minLengthValid() {
      return !(this.minLength > 0 && this.content?.length < this.minLength);
    },
    maxLengthValid() {
      return !(this.maxLength > 0 && this.content?.length > this.maxLength);
    },
    state() {
      if (!this.validForm) return null;
      if (!this.requiredValid) return false;
      if (!this.lengthValid) return false;
      if (!this.minLengthValid) return false;
      if (!this.maxLengthValid) return false;
      if (this.hasError) return false;

      return true;
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
            name: this.$t(this.label),
          })
        );

      if (!this.lengthValid)
        return (
          this.lengthMessage ||
          this.$t('CAMPOS_VALIDACAO.TAMANHO').formatUnicorn({
            name: this.label,
            length: this.length,
          })
        );
      if (!this.minLengthValid)
        return (
          this.minLengthMessage ||
          this.$t('CAMPOS_VALIDACAO.COMPRIMENTO_MINIMO').formatUnicorn({
            name: this.label,
            length: this.minLength,
          })
        );
      if (!this.maxLengthValid)
        return (
          this.maxLengthMessage ||
          this.$t('CAMPOS_VALIDACAO.COMPRIMENTO_MAXIMO').formatUnicorn({
            name: this.label,
            length: this.maxLength,
          })
        );

      if (this.hasError) return this.errorMessage;
      return '';
    },
  },
  methods: {
    // FUNÇÕES DE FORMATAÇÃO:
    regexCNPJ(value) {
      // Removendo qualquer letra q o user tentar inserir:
      value = value.replace(/\D/g, '');
      // Regex para CNPJ:
      value = formatador.cnpjComPontuacao(value);
      return value;
    },
    formatter(e) {
      if (e) {
        if (this.maxLength > 0 && e.length > this.maxLength) {
          e = e.substring(0, this.maxLength);
        }
        e = this.regexCNPJ(e);
      }
      this.content = e;
      return e;
    },
    // FUNÇÕES DE GERENCIAMENTO DE ESTADO:
    handleInput() {
      this.$emit('input', this.content);
      this.hasError = false;
    },
    valid() {
      this.validForm = true;
      return this.state;
    },
    sendKeydownEvent() {
      if (this.maxLength) {
        this.lengthLimit = `${this.content.length}/${this.maxLength}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
input[readonly] {
  background-color: #f2f3f8 !important;
}
.lengthLimit__text {
  float: right;
}
</style>
