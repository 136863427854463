<template>
  <b-form-group>
    <template #label>
      <span>{{ $t(label) }}</span>
      <span v-if="required && label" class="text-danger">*</span>
    </template>
    <b-input-group
      v-bind:class="{ 'is-valid': state, 'is-invalid': state == false }"
    >
      <b-form-datepicker
        v-if="showDatetime || showDate"
        v-model="content"
        format="DD/MM/yyyy"
        label-no-date-selected="DD/MM/YYYY"
        language="pt-BR"
        locale="pt-BR"
        :initial-date="min"
        :state="state"
        :max="max"
        :min="min"
        reset-button
        :label-reset-button="$t('GERAL.LIMPAR')"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
        :disabled="disabled"
        @input="setChanged"
      />
      <b-form-timepicker
        v-if="showDatetime || showTime"
        v-model="contentTime"
        :state="state"
        :disabled="disabled"
        @input="setChanged"
      ></b-form-timepicker>
      <b-form-invalid-feedback>{{ feedback }}</b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
import helpers from '@/common/utils/helpers';

export default {
  name: 'PortoInputDate',
  props: {
    value: { type: [String, Date], default: '' },
    label: { type: String, required: true },
    required: { type: Boolean },
    requiredMessage: { type: String },
    validate: { type: Boolean, default: null },
    disabled: { type: Boolean, default: false },
    max: { type: [String, Date], default: null },
    min: { type: [String, Date], default: null },
    type: { type: String, default: 'date' },
  },
  data() {
    return {
      content: helpers.formatarDataEn(this.value),
      contentTime: this.showTime
        ? this.value
        : helpers.retornarHora(this.value),
      validarFormulario: false,
      hasError: false,
      errorMessage: '',
      changed: false,
      isField: true,
    };
  },
  computed: {
    wasChanged() {
      return this.changed;
    },
    showDatetime() {
      return this.type == 'dateTime';
    },
    showDate() {
      return this.type == 'date';
    },
    showTime() {
      return this.type == 'time';
    },
    valueDatetime() {
      if (this.showTime) return this.contentTime;
      return helpers.formatarDataUtc(
        `${this.content} ${this.contentTime}`,
        true
      );
    },
    requiredValid() {
      return !(this.required && this.value == '');
    },
    state() {
      if (!this.validarFormulario) return null;

      if (!this.requiredValid) return false;
      if (this.hasError) return false;
      if (this.validate != null) return this.validate;
      return true;
    },
    feedback() {
      if (!this.validarFormulario) return '';
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
            name: this.label,
          })
        );
      if (this.validate != null && !this.validate)
        return (
          this.invalidEmailMessage ||
          this.$t('CAMPOS_VALIDACAO.INVALIDO').formatUnicorn({
            name: this.label,
          })
        );
      if (this.hasError) return this.errorMessage;
      return '';
    },
  },
  methods: {
    showError(message) {
      this.validarFormulario = true;
      this.hasError = true;
      this.errorMessage = message;
    },
    valid() {
      this.validarFormulario = true;
      return this.state;
    },
    clear() {
      this.content = '';
      this.contentTime = '';
      this.validarFormulario = false;
      this.$emit('input', this.contentTime);
    },
    setChanged() {
      this.changed = true;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.content = helpers.formatarDataEn(value);
        this.contentTime = this.showTime ? value : helpers.retornarHora(value);
      },
      deep: true,
      immediate: true,
    },
    valueDatetime(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
  },
};
</script>
<style>
.is-valid button {
  border-color: #7dba19;
}
.is-invalid button {
  border-color: #dc3545;
}
</style>
