<template>
  <div class="container-file row">
    <div class="col-12 col-sm-6">
      <b-form-group :label="label">
        <b-form-file
          v-model="content"
          :state="Boolean(content)"
          accept=".jpg, .png, .jpeg"
          @input="imagePreview"
          @change="imagePreview"
          plain
          :placeholder="placeholder"
          :drop-placeholder="dropPlaceholder"
        >
      </b-form-file>
      </b-form-group>
       {{ fotoExistente }}
    </div>
    <div v-if="content.name" class="input-file__container-img col-12 col-md-4">
      <p>{{ textoDeAviso }}</p>
      <img class="previewImg" :src="`/img/${fotoExistente}`" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputFile',
  props: {
    placeholder: { type: String, default: 'Arraste a imagem escolhida' },
    dropPlaceholder: {
      type: String,
      default: 'Solte o arquivo nesse local...',
    },
    fotoExistente: { type: String, default: '' },
    label: { type: String },
  },
  computed: {
    textoDeAviso() {
      return this.content.name || this.fotoExistente
        ? 'Arquivo selecionado:'
        : 'Selecione um arquivo.';
    },
  },
  data() {
    return {
      content: [],
    };
  },

  methods: {
    imagePreview(e) {
      if (e?.target) {
        let file = new FileReader();
        const { files } = e.target;
        if (files && files[0]) {
          file.onload = function (e) {
            document.querySelector('.previewImg').src = e.target.result;
          };
        }
        const filesResult = {
          nome: files[0].name,
          tipo: files[0].type,
          src: file,
        };
        file.readAsDataURL(files[0]);
        this.$emit('input', filesResult);
      }
    },
  },
};
</script>

<style scoped>
.container-file {
  display: flex;
}
.input-file__container-img {
  display: block;
}
.input-file__container-img > p {
  margin: 0;
  width: 100%;
}
.input-file__container-img img {
  width: 200px;
  height: 200px;
}
</style>
