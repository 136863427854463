<template>
  <b-form-group :label-sr-only="noLabel">
    <template #label>
      <span>{{ $t(label) }}</span>
      <span v-if="required && label" class="text-danger">*</span>
    </template>
    <b-input-group>
      <vue-editor
        ref="campo"
        :value="internalValue"
        :editorOptions="editorSettings"
        :editorToolbar="editorOptions"
        @input="updateInternalValue"
      />
    </b-input-group>
    <b-form-invalid-feedback :state="state">{{
      feedback
    }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);

export default {
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: { type: String },
    noLabel: { type: Boolean, default: false },
    editorToolbar: {
      type: Array,
      default: () => [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '+1' }, { indent: '-1' }],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        ['link', 'blockquote'],
        [{ color: [] }, { background: [] }],
      ],
    },

    required: { type: Boolean },
  },
  data() {
    return {
      editorOptions: this.editorToolbar,
      internalValue: this.value,
      validarFormulario: false,
      hasError: false,
      errorMessage: '',
      isField: true,
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
    };
  },
  computed: {
    requiredValid() {
      return !(this.required && !this.value);
    },
    state() {
      if (!this.validarFormulario) return null;

      if (!this.requiredValid) return false;
      if (this.hasError) return false;

      return true;
    },
    feedback() {
      if (!this.requiredValid)
        return (
          this.requiredMessage ||
          this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
            name: this.label,
          })
        );

      if (this.hasError) return this.errorMessage;
      return '';
    },
  },
  watch: {
    value(newValue) {
      // Update internalValue when the prop 'value' changes
      this.internalValue = newValue;
    },
  },
  methods: {
    updateInternalValue(value) {
      this.internalValue = value;
      this.$emit('input', value);
    },
    valid() {
      this.validarFormulario = true;
      return this.state;
    },
  },
};
</script>

<style>
.quillWrapper {
  overflow: hidden;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 8px !important;
  border-radius: 0.28571429rem;
  width: 100%;
  background-color: white;
}

.quillWrapper .ql-snow.ql-toolbar {
  border-radius: 8px 8px 0 0;
}

.quill-container {
  border: 0 !important;
}

.ql-container.ql-snow {
  border: 0 !important;
}

.quillWrapper > .ql-toolbar.ql-snow {
  border: 0 !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
}
</style>
